<template>
    <div>
        <b-card>
            <b-row align-h="start" class="text-left align-items-center mt-1">
                <b-col md="3">
                    <b-form-group label="Teacher" invalid-feedback="Teacher is required." ref="teacher">
                        <v-select v-model="myObj.teacherID" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="teachers" :clearable="false" label="name" :reduce="(val) => val.id" ref="teacher"
                            @input="loadTeacherData()" placeholder="Select teacher" :loading="isTeacherLoading" />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Class" invalid-feedback="Class is required." ref="class">
                        <v-select v-model="myObj.clsID" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="classesOptions" :clearable="false" label="name" :reduce="(val) => val.id"
                            input-id="id" ref="class" @input="FillSections()" placeholder="Select class."
                            :disabled="!myObj.teacherID" :loading="isClassLoading" />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Section" invalid-feedback="section is required." ref="sec">
                        <v-select ref="sec" v-model="myObj.sec" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="currentSections" :reduce="(val) => val.id" label="section" :clearable="false"
                            placeholder="Select section." :disabled="!myObj.clsID" @input="loadTeacherSubjects"
                            :loading="isSectionLoading" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>

        <!-- Subject Selection -->
        <b-card class="mt-2" v-if="teacherSubjects.length > 0">
            <h5 class="mb-2">Select Subject</h5>
            <div class="subject-pills">
                <b-button v-for="(subject, index) in teacherSubjects" :key="subject.subID" pill
                    :variant="`outline-${colors[index % colors.length]}`" @click="selectSubject(subject)">
                    {{ subject.subject }}

                </b-button>
                <!-- <div
                    v-for="(subject, index) in teacherSubjects"
                    :key="subject.subID"
                    class="subject-pill"
                    :class="{
                        'selected': selectedSubject === subject.subID,
                        [`pill-${colors[index % colors.length]}`]: true
                    }"
                    @click="selectSubject(subject)"
                >
                    {{ subject.subject }}
                </div> -->
            </div>
        </b-card>

        <!-- Calendar View -->
        <b-card class="mt-2" v-if="selectedSubject">
            <div class="d-flex flex-column">
                <!-- Date Selection -->
                <div class="date-selection mb-4">
                    <h5 class="text-center mb-3">Select the date</h5>
                    <div class="d-flex justify-content-center">
                        <div class="calendar-wrapper" style="width: 100%; max-width: 400px;">
                            <flat-pickr v-model="selectedDate" :config="calendarConfig" class="form-control" />
                        </div>
                    </div>
                </div>

                <!-- Homework Type Selection -->
                <div v-if="selectedDate" class="homework-type-selection">
                    <h5 class="text-center mb-3">Select Type</h5>
                    <div class="d-flex justify-content-center gap-3">
                        <b-button variant="outline-primary" class="type-btn"
                            :class="{ 'selected': selectedType === 'homework' }" @click="selectType('homework')">
                            <feather-icon icon="BookIcon" size="18" class="mr-1" />
                            <span>Homework</span>
                        </b-button>
                        <b-button variant="outline-primary" class="type-btn"
                            :class="{ 'selected': selectedType === 'classwork' }" @click="selectType('classwork')">
                            <feather-icon icon="EditIcon" size="18" class="mr-1" />
                            <span>Classwork</span>
                        </b-button>
                    </div>
                    <div class="text-center mt-2">
                        <small class="text-muted">Selected Date: {{ formatDate(selectedDate) }}</small>
                    </div>
                </div>
            </div>

            <!-- Details Form -->
            <div v-if="selectedType" class="mt-3">
                <div class="d-flex justify-content-between align-items-center mb-2">
                    <h5 class="mb-0">{{ selectedType === 'homework' ? 'Homework' : 'Classwork' }} Details</h5>
                    <div class="d-flex align-items-center">
                        <small class="text-muted mr-2">{{ formatDate(selectedDate) }}</small>
                        <b-button variant="outline-secondary" size="sm" @click="changeDate">
                            <feather-icon icon="CalendarIcon" size="14" />
                        </b-button>
                    </div>
                </div>

                <b-form-group>
                    <b-form-textarea v-model="homeworkDetails" :placeholder="'Enter ' + selectedType + ' details...'"
                        rows="3" />
                </b-form-group>

                <!-- File Upload Section -->
                <b-form-group label="Attachments (Max 50MB per file)">
                    <div class="file-upload-zone" @dragover.prevent="onDragOver" @dragleave.prevent="onDragLeave"
                        @drop.prevent="onDrop" :class="{ 'drag-over': isDragging }">
                        <div class="upload-content" v-if="!isUploading">
                            <feather-icon icon="UploadCloudIcon" size="38" class="mb-1" />
                            <p class="mb-0">Drag and drop files here or</p>
                            <input type="file" ref="fileInput" multiple class="d-none" @change="onFileSelect" />
                            <b-button variant="outline-primary" size="sm" class="mt-1" @click="$refs.fileInput.click()">
                                Browse Files
                            </b-button>
                        </div>
                        <div v-else class="upload-loading">
                            <b-spinner variant="primary" />
                            <p class="mt-1 mb-0">Uploading files...</p>
                        </div>
                    </div>

                    <!-- Selected Files List -->
                    <div v-if="selectedFiles.length > 0" class="selected-files mt-2">
                        <div v-for="(file, index) in selectedFiles" :key="index" class="file-item">
                            <div class="file-info">
                                <feather-icon :icon="getFileIcon(file.type)" size="16" class="mr-1" />
                                <span class="file-name">{{ file.name }}</span>
                                <small class="file-size">({{ formatFileSize(file.size) }})</small>
                            </div>
                            <b-button variant="text" size="sm" class="remove-file" @click="removeFile(index)">
                                <feather-icon icon="XIcon" size="14" />
                            </b-button>
                        </div>
                    </div>
                </b-form-group>

                <div class="d-flex justify-content-end">
                    <b-button variant="primary" :disabled="!homeworkDetails" @click="saveHomework">
                        <feather-icon icon="SaveIcon" class="mr-50" size="16" />
                        <span>Save {{ selectedType === 'homework' ? 'Homework' : 'Classwork' }}</span>
                    </b-button>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BBadge,
    BButton,
    BAvatar,
    BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions } from "vuex";

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BBadge,
        BButton,
        BAvatar,
        BFormTextarea,
        vSelect,
        flatPickr,
    },
    data() {
        return {
            classes: [],
            classesOptions: [],
            currentSections: [],
            teachers: [],
            subjects: [],
            selectedSubject: null,
            selectedDate: null,
            homeworkDetails: "",
            colors: ["primary", "success", "danger", "warning", "info"],
            myObj: {
                campusID: this.$store.state.userData.cId,
                teacherID: 0,
                clsID: 0,
                sec: 0,
            },
            calendarConfig: {
                altFormat: "j M, Y",
                altInput: true,
                dateFormat: "Y-m-d",
                minDate: "today",
            },
            reportDomain: this.$store.state.report.replace(/_/g, ""),
            dataLoading: false,
            subObj: null,
            teacherSubjects: [],
            subjectIcons: {
                'Arts': 'BrushIcon',
                'Urdu-Language': 'TypeIcon',
                'Assignment': 'FileTextIcon',
                'Attendance': 'UserCheckIcon',
                'Mathematics': 'PlusCircleIcon',
                'Science': 'ZapIcon',
                'English': 'BookIcon',
                'History': 'ClockIcon',
                'Geography': 'GlobeIcon',
                'Computer': 'MonitorIcon',
                'default': 'BookOpenIcon'
            },
            selectedType: null,
            isTeacherLoading: false,
            isClassLoading: false,
            isSectionLoading: false,
            selectedFiles: [],
            isDragging: false,
            isUploading: false,
        };
    },
    async created() {
        await this.loadTeachers();
        await this.loadSettings();
    },
    methods: {
        ...mapActions({
            get: "get",
            post: "post",
            put: "put",
            delete: "delete",
        }),
        async loadTeachers() {
            this.isTeacherLoading = true;
            try {
                const response = await this.get({
                    url: `${this.$store.state.domain}staff/filter?db=${this.$store.state.userData.db}&cId=${this.$store.state.userData.cId}&dptID=0&status=present`,
                    token: this.$store.state.userData.token,
                });
                this.teachers = response;
            } catch (error) {
                console.error("Error loading teachers:", error);
            } finally {
                this.isTeacherLoading = false;
            }
        },
        async loadSettings() {
            try {
                let res = await this.post({
                    url: `${this.$store.state.domain}Settings/getSettings?db=${this.$store.state.userData.db}&cID=${this.myObj.campusID}`,
                    body: ["subject_class"],
                    context: this,
                    token: this.$store.state.userData.token,
                    subjects: true,
                });
                this.subObj = res.find((el) => el.key == "subject_class");
            } catch (error) {
                console.error("Error loading settings:", error);
            }
        },
        async loadTeacherData() {
            if (!this.myObj.teacherID) return;

            this.isClassLoading = true;
            try {
                console.log('Selected Teacher ID:', this.myObj.teacherID);
                console.log('Selected Teacher:', this.teachers.find(t => t.id === this.myObj.teacherID));

                // First load the classes
                const classesResponse = await this.get({
                    url: `${this.$store.state.domain}myclasses/LoadClasses?db=${this.$store.state.userData.db}&cId=${this.myObj.campusID}&dID=0`,
                    token: this.$store.state.userData.token,
                });

                // Then load teacher's assigned classes
                const teacherResponse = await this.get({
                    url: `${this.$store.state.domain}TeacherClasses/LoadDataWebNew?db=${this.$store.state.userData.db}&tID=${this.myObj.teacherID}`,
                    token: this.$store.state.userData.token,
                });

                // Filter classes based on teacher's assignments
                const teacherClasses = teacherResponse.map(tc => tc.clsID);
                console.log('Teacher Assigned Class IDs:', teacherClasses);

                this.classes = classesResponse.filter(c => teacherClasses.includes(c.cls.id));
                this.classesOptions = this.classes.map(c => c.cls);
                console.log('Available Classes:', this.classesOptions);

                // Reset dependent fields
                this.myObj.clsID = 0;
                this.myObj.sec = 0;
                this.currentSections = [];
                this.subjects = [];
                this.selectedSubject = null;
            } catch (error) {
                console.error("Error loading teacher data:", error);
            } finally {
                this.isClassLoading = false;
            }
        },
        FillSections(text) {
            if (!this.myObj.clsID) {
                this.currentSections = [];
                return;
            }

            console.log('Selected Class ID:', this.myObj.clsID);
            const selectedClass = this.classes.find(elem => elem.cls.id === this.myObj.clsID);
            console.log('Selected Class:', selectedClass);

            if (!selectedClass) {
                this.currentSections = [];
                return;
            }

            if (this.reportDomain === "myskoolmakjauhar") {
                this.currentSections = selectedClass.sections.length > 0
                    ? [{ id: 0, section: "All Sections" }, ...selectedClass.sections]
                    : [{ id: 0, section: "All Sections" }];
            } else {
                this.currentSections = selectedClass.sections.length > 0
                    ? selectedClass.sections
                    : [];
            }

            console.log('Available Sections:', this.currentSections);

            if (text !== "filled") {
                this.myObj.sec = 0;
            }

            this.subjects = [];
            this.selectedSubject = null;
        },
        async loadTeacherSubjects() {
            if (!this.myObj.teacherID || !this.myObj.clsID || !this.myObj.sec) {
                this.teacherSubjects = [];
                return;
            }

            this.isSectionLoading = true;
            try {
                const response = await this.get({
                    url: `${this.$store.state.domain}TeacherClasses/LoadDataWebNew?db=${this.$store.state.userData.db}&tID=${this.myObj.teacherID}`,
                    token: this.$store.state.userData.token,
                });

                // Find the matching class and section combination
                const teacherClass = response.find(tc =>
                    tc.clsID === this.myObj.clsID &&
                    tc.secID === this.myObj.sec
                );

                if (teacherClass && teacherClass.subjects) {
                    this.teacherSubjects = teacherClass.subjects;
                    console.log('Loaded teacher subjects:', this.teacherSubjects);
                } else {
                    this.teacherSubjects = [];
                    this.$bvToast.toast('No subjects found for this class and section', {
                        title: 'Note',
                        variant: 'warning',
                        solid: true
                    });
                }

                // Reset selection
                this.selectedSubject = null;
                this.selectedDate = null;
                this.homeworkDetails = '';

            } catch (error) {
                console.error("Error loading teacher subjects:", error);
                this.teacherSubjects = [];
                this.$bvToast.toast('Error loading subjects. Please try again.', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                });
            } finally {
                this.isSectionLoading = false;
            }
        },
        getSubjectIcon(subjectName) {
            // Clean up subject name and check for icon
            const cleanName = subjectName.trim().replace(/^[^a-zA-Z]+/, '');
            return this.subjectIcons[cleanName] || this.subjectIcons.default;
        },
        selectSubject(subject) {
            console.log('Selecting subject:', subject);
            this.selectedSubject = this.selectedSubject === subject.subID ? null : subject.subID;

            // Update homework object
            if (this.selectedSubject) {
                this.myObj.homework.subID = subject.subID;
                this.myObj.homework.subject = subject.subject;
            } else {
                this.myObj.homework.subID = 0;
                this.myObj.homework.subject = "";
            }

            // Clear date and homework details when changing subject
            if (this.selectedSubject === null) {
                this.selectedDate = null;
                this.homeworkDetails = '';
            }

            if (this.selectedSubject) {
                this.$bvToast.toast(`Selected subject: ${subject.subject}`, {
                    title: 'Subject Selected',
                    variant: 'success',
                    solid: true
                });
            }
        },
        prevMonth() {
            const fp = document.querySelector(".flatpickr-input")._flatpickr;
            fp.changeMonth(-1);
        },
        nextMonth() {
            const fp = document.querySelector(".flatpickr-input")._flatpickr;
            fp.changeMonth(1);
        },
        formatDate(date) {
            if (!date) return '';
            return new Date(date).toLocaleDateString('en-US', {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        },
        selectType(type) {
            this.selectedType = type;
        },
        changeDate() {
            this.selectedDate = null;
            this.selectedType = null;
            this.homeworkDetails = '';
        },
        async saveHomework() {
            if (!this.selectedSubject || !this.selectedDate || !this.homeworkDetails || !this.selectedType) return;

            try {
                const homework = {
                    teacherID: this.myObj.teacherID,
                    classID: this.myObj.clsID,
                    sectionID: this.myObj.sec,
                    subjectID: this.selectedSubject,
                    date: this.selectedDate,
                    details: this.homeworkDetails,
                    campusID: this.myObj.campusID,
                    type: this.selectedType
                };

                await this.post({
                    url: `${this.$store.state.domain}Homework?db=${this.$store.state.userData.db}`,
                    body: homework,
                    message: `${this.selectedType === 'homework' ? 'Homework' : 'Classwork'} saved successfully!`,
                    context: this,
                    token: this.$store.state.userData.token,
                });

                // Reset form
                this.selectedDate = null;
                this.homeworkDetails = "";
                this.selectedType = null;
            } catch (error) {
                console.error("Error saving homework:", error);
            }
        },
        getFileIcon(fileType) {
            if (fileType.includes('image')) return 'ImageIcon';
            if (fileType.includes('pdf')) return 'FileTextIcon';
            if (fileType.includes('word') || fileType.includes('document')) return 'FileIcon';
            if (fileType.includes('sheet') || fileType.includes('excel')) return 'GridIcon';
            if (fileType.includes('presentation') || fileType.includes('powerpoint')) return 'MonitorIcon';
            return 'FileIcon';
        },
        formatFileSize(bytes) {
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const sizes = ['Bytes', 'KB', 'MB', 'GB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        },
        onDragOver(e) {
            this.isDragging = true;
        },
        onDragLeave(e) {
            this.isDragging = false;
        },
        async onDrop(e) {
            this.isDragging = false;
            const files = Array.from(e.dataTransfer.files);
            await this.validateAndAddFiles(files);
        },
        async onFileSelect(e) {
            const files = Array.from(e.target.files);
            await this.validateAndAddFiles(files);
        },
        async validateAndAddFiles(files) {
            const maxSize = 50 * 1024 * 1024; // 50MB in bytes
            const validFiles = files.filter(file => {
                if (file.size > maxSize) {
                    this.$bvToast.toast(`File "${file.name}" exceeds 50MB limit`, {
                        title: 'File too large',
                        variant: 'warning',
                        solid: true
                    });
                    return false;
                }
                return true;
            });

            if (validFiles.length > 0) {
                this.isUploading = true;
                try {
                    // Simulate upload delay - Replace with actual upload logic
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    this.selectedFiles.push(...validFiles);
                } finally {
                    this.isUploading = false;
                }
            }
        },
        removeFile(index) {
            this.selectedFiles.splice(index, 1);
        },
    },
};
</script>

<style lang="scss">
.subject-pills {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 1rem;
}

.date-selection,
.homework-type-selection {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.calendar-wrapper {
    .flatpickr-input {
        background-color: white;
    }
}

.type-btn {
    min-width: 140px;
    padding: 0.8rem 1.5rem;
    margin: 0 0.5rem;
    transition: all 0.2s ease;

    &:hover {
        transform: translateY(-2px);
    }

    &.selected {
        background-color: #7367f0;
        color: white;
        border-color: #7367f0;

        &:hover {
            background-color: #685dd8;
            border-color: #685dd8;
        }
    }
}

.gap-3 {
    gap: 1rem;
}

.file-upload-zone {
    border: 2px dashed #d8d6de;
    border-radius: 0.428rem;
    padding: 2rem;
    text-align: center;
    transition: all 0.2s ease;
    background: #f8f8f8;
    cursor: pointer;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.drag-over {
        border-color: #7367f0;
        background: rgba(115, 103, 240, 0.05);
    }

    .upload-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #6e6b7b;

        .feather-icon {
            color: #7367f0;
        }
    }

    .upload-loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #6e6b7b;
    }
}

.selected-files {
    .file-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        background: #f8f8f8;
        border-radius: 0.357rem;
        margin-bottom: 0.5rem;

        .file-info {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .file-name {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .file-size {
                color: #6e6b7b;
            }
        }

        .remove-file {
            padding: 0;
            color: #ea5455;

            &:hover {
                color: #e42728;
            }
        }
    }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>